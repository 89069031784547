<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-29 10:57:32
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-12-10 15:20:02
 * @Description  : 创建运单页面
-->
<template>
  <div class="container">
    <div class="title font-heavy">{{ $t("new_shipment") }}</div>
    <!-- <div>{{ $t("alert_tip_key_for_create") }}</div> -->
    <div class="tip">
      <div style="font-weight: 500">{{ $t("choose_template") }}</div>
      <span style="margin: 0 20px">{{ $t("or") }}</span>
      <span class="without-template" @click="withoutTemp()">{{
        $t("continue_without_a_template")
      }}</span>
    </div>
    <div class="card-box">
      <el-card
        class="card-style"
        v-for="(item, index) in systemTemplates"
        :key="index"
        :value="item.name"
      >
        <div>{{ $t(item.name) }}</div>
        <div>
          {{ Number(item.config.min) | convertTemperature(setSelectedTempType)
          }}{{ setSelectedTempType }}

          ~
          {{ Number(item.config.max) | convertTemperature(setSelectedTempType)
          }}{{ setSelectedTempType }}
        </div>
        <el-button type="primary" @click="withTemp(item)">{{
          $t("choose")
        }}</el-button>
      </el-card>
    </div>
    <div
      v-if="savedTemplates.length > 0"
      class="font-heavy"
      style="font-size: 18px;"
    >
      {{ $t("select_saved_template") }}
    </div>
    <div class="card-box">
      <el-card
        class="card-style"
        v-for="(item, index) in savedTemplates"
        :key="index"
        :value="item.name"
      >
        <div>{{ $t(item.name) }}</div>
        <div>
          {{ Number(item.config.min) | convertTemperature(setSelectedTempType)
          }}{{ setSelectedTempType }}

          ~
          {{ Number(item.config.max) | convertTemperature(setSelectedTempType)
          }}{{ setSelectedTempType }}
        </div>
        <el-button type="primary" @click="withTemp(item)">{{
          $t("choose")
        }}</el-button>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getShipmentTemplateListApi } from "@/api/api.js";

export default {
  name: "CreateShipment",
  data() {
    return {
      // isTemp: true,
      templateList: [],
      savedTemplates: [], // 保存的模板数组
      systemTemplates: [], // 系统模板数组
      // unit: "C",
    };
  },
  methods: {
    withoutTemp() {
      this.goPages("/createShipmentWithoutTemplate");
    },
    withTemp(item) {
      console.log("item: ", JSON.stringify(item));
      this.goPages("/createShipmentWithTemplate", {
        item: JSON.stringify(item),
      });
    },
    /**
     * 模板标志位templateInd 是否为模板  1：模板， 9：系统模板，1：name为中英文的key，9：name直接显示
     */
    getShipmentTemplateList() {
      getShipmentTemplateListApi({
        // unit: this.setSelectedTempType == "°C" ? "C" : "F",
      }).then((r) => {
        console.log("=====", r);
        this.templateList = r.data;

        // 初始化保存的模板和系统模板数组
        this.savedTemplates = [];
        this.systemTemplates = [];

        // 遍历数据并根据 templateInd 分类
        this.templateList.forEach((item) => {
          if (item.templateInd === "1") {
            this.savedTemplates.push(item);
          } else if (item.templateInd === "9") {
            this.systemTemplates.push(item);
          }
        });

        console.log("this.templateList: ", JSON.stringify(this.templateList));
        console.log(
          "this.systemTemplates: ",
          JSON.stringify(this.systemTemplates)
        );

        console.log(
          "this.savedTemplates: ",
          JSON.stringify(this.savedTemplates)
        );
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },

    setSelectedTempType(newVal) {
      console.log("newVal: ", newVal);
      // this.unit = newVal == "°C" ? "C" : "F";
      this.getShipmentTemplateList();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;

    this.getShipmentTemplateList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  // padding: 20px;
  padding-top: 16px;
  margin-left: 38px;
  .title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
    width: 100%;
    margin-top: 10px;
  }
  .card-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;

    .card-style {
      width: 30%;
      margin: 10px;
    }
  }
  .tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin: 10px 0;
    span {
      color: #1a72f4;
    }
  }
  .without-template {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
